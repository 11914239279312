import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import { DealCompanyStageEnumType } from '__generated__/graphql';
import { FC } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';
import LoadingIndicator from 'primitives/LoadingIndicator';

import constants from 'utils/constants';
import stageToReadable from 'utils/enums/stage-to-readable';
import { formatCurrencyToShort } from 'utils/format-helper';

import alIndiaLogo from '../../../../../media/alIndiaLogo.png';

const DEAL_DETAILS_QUERY = gql(`
  query GetDealDetailsForInvitePreview($id: ID!) {
    deal(id: $id) {
      companyInformation {
        memo
        stage
        industries
        company {
          name
        }
      }
      syndicate {
        name
      }
      allocation
      dealTerms {
        minimumInvestment
      }
      totalCarryPercentage
    }
  }
`);

const InviteUsersEmailPreviewDialogButton: FC<{
  open: boolean;
  setOpen: (boolean) => void;
  handleToastOpen: () => void;
  onSubmit: () => void;
  formData: any;
  dealId: string;
}> = ({ open, setOpen, handleToastOpen, onSubmit, formData, dealId }) => {
  const { loading, error, data, refetch } = useQuery(DEAL_DETAILS_QUERY, {
    variables: {
      id: dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const deal = data.deal;

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Email Preview</DialogTitle>
          <DialogSubTitle>An example of what your email invite will look like.</DialogSubTitle>
          <div className="border p-4 mb-4">
            <img src={alIndiaLogo} width={150} className="mx-auto mb-8" alt="AL India Logo" />
            <div className="flex flex-row gap-4 mx-auto w-full">
              <img className="h-8 w-8 rounded-full" src={constants.ALT_AVATAR_URL} alt="Logo" />
              <h3>
                <span className="font-bold">{deal.syndicate.name}</span> is investing in{' '}
                <span className="font-bold">{deal.companyInformation.company.name}</span>
              </h3>
            </div>
            {formData?.note && (
              <>
                <h3 className="font-bold text-md mt-12">Message from the GP</h3>
                <div className="prose prose-md mt-4 text-gray-500 border p-4">{formData?.note}</div>
              </>
            )}

            <div className="grid grid-cols-2 gap-2 justify-between mt-8 px-4">
              <p className="font-bold">Stage</p>
              <p>
                {
                  stageToReadable(
                    deal.companyInformation.stage || DealCompanyStageEnumType.PreLaunch
                  ).label
                }
              </p>
              <p className="font-bold">Allocation</p>
              <p>{formatCurrencyToShort(deal.allocation)}</p>
              <p className="font-bold">Min. Investment</p>
              <p>
                {formatCurrencyToShort(
                  formData?.minimumInvestmentAmount || deal.dealTerms?.minimumInvestment
                )}
              </p>
              <p className="font-bold">Carry</p>
              <p>{deal.totalCarryPercentage}%</p>
            </div>
            <div className="mt-8 w-full border bg-slate-800 rounded-md">
              <p className="text-gray-200 text-xs p-2 text-center">View deal and invest</p>
            </div>
          </div>
          <Button
            onClick={() => {
              onSubmit();
              handleToastOpen();
              setOpen(false);
            }}
            loading={loading}
          >
            Send
          </Button>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default InviteUsersEmailPreviewDialogButton;
