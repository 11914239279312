import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

const UPDATE_FUND_MUTATION = gql(`
  mutation UpdateFund(
    $id: ID!
    $name: String!
    $closingDate: String!
    $image: Upload
    $description: String
  ) {
    updateFund(
      id: $id
      name: $name
      closingDate: $closingDate
      image: $image
      description: $description
    ) {
      id
      name
      closingDate
      image
      description
    }
  }
`);

const UpdateFund: FC<{
  id: string;
  name: string;
  image?: string | null;
  description?: string | null;
  closingDate: string;
}> = ({ id, name, image, description, closingDate }) => {
  const [updateFund, { loading, error }] = useMutation(UPDATE_FUND_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateFund({
          variables: {
            id,
            name: data.name,
            image: typeof data.image !== 'string' ? data.image : null,
            description: data.description,
            closingDate: data.closingDate,
          },
        });
      }}
      title="Information"
    >
      <FormInput type="text" fieldName="name" label="Name" defaultValue={name} />
      <FormInput type="avatar" fieldName="image" label="Logo" defaultValue={image || null} />
      <FormInput
        fullWidth
        type="custom"
        fieldName="description"
        label="Description"
        defaultValue={description}
        customInput={EditorFormInput}
        validators={{
          required: true,
        }}
      />
      <FormInput
        type="date"
        fieldName="closingDate"
        label="Closing Date"
        defaultValue={closingDate}
        validators={{
          required: true,
        }}
        hidden
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateFund;
