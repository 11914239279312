import { ChevronRightIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import TextField from 'primitives/TextField';

import constants from 'utils/constants';

type SyndicateConversationType = {
  id: string;
  updatedAt: string;
  users: {
    id: string;
    user: {
      id: string;
      name: string;
      image: {
        url: string | null;
      };
    };
    isLead: boolean;
  }[];
};

type InvestorConversationType = {
  id: string;
  updatedAt: string;
  syndicate?: {
    id: string;
    image?: string | null;
    name: string;
  } | null;
};

const RecipientsPanel = ({
  syndicateId,
  conversations,
  onNewConversationClick,
}: {
  syndicateId?: string;
  conversations: {
    nodes: (SyndicateConversationType | InvestorConversationType)[];
  };
  onNewConversationClick: () => void;
}) => {
  const [filter, setFilter] = useState('');

  const filteredConversations = conversations.nodes.filter(conversation => {
    if (filter === '') {
      return true;
    }

    if ('users' in conversation) {
      return conversation.users
        .filter(user => user.isLead !== true)
        .map(user => user.user.name.toLowerCase())
        .join(', ')
        .includes(filter.toLowerCase());
    }

    if ('syndicate' in conversation) {
      return conversation.syndicate?.name.toLowerCase().includes(filter.toLowerCase());
    }

    return false;
  });

  return (
    <>
      <div className="w-full px-3 py-2.5">
        <TextField placeholder="Search" onChange={e => setFilter(e.target.value)} fullWidth />
        <button
          className="text-xs font-semibold text-indigo-500 pb-0 border-b border-indigo-200 hover:border-indigo-500 hover:text-indigo-600"
          onClick={() => onNewConversationClick()}
        >
          Start a new conversation
        </button>
      </div>
      <ul className="divide-y divide-gray-100 ring-1 ring-gray-900/5 bg-white h-full">
        {filteredConversations.length === 0 ? (
          <p className="p-4 text-center text-gray-500">
            {filter ? 'No results found' : 'You have no conversations yet.'}
          </p>
        ) : (
          <>
            {filteredConversations.map(conversation => {
              function getNameAndImage() {
                if (syndicateId && 'users' in conversation) {
                  return {
                    name: conversation.users
                      .filter(user => user.isLead !== true)
                      .map(user => user.user.name)
                      .join(', '),
                    image: conversation.users.filter(user => user.isLead !== true)[0]?.user.image
                      ?.url,
                  };
                }

                if ('syndicate' in conversation && conversation.syndicate) {
                  return {
                    name: conversation.syndicate?.name,
                    image: conversation.syndicate?.image,
                  };
                }

                if ('users' in conversation) {
                  return {
                    name: `${conversation.users.map(user => user.user.name).join(', ')}`,
                    image: conversation.users[0]?.user.image?.url,
                  };
                }

                return {
                  name: 'Missing Name',
                  image: constants.ALT_AVATAR_URL,
                };
              }

              return (
                <li key={conversation.id}>
                  <NavLink
                    to={
                      syndicateId
                        ? `/syndicate-dashboard/${syndicateId}/conversations/${conversation.id}`
                        : `/invest/conversations/${conversation.id}`
                    }
                    className={({ isActive }) =>
                      classNames(
                        'focus:outline-none relative flex justify-between gap-x-6 px-3 py-2.5',
                        {
                          'hover:bg-gray-50': !isActive,
                          'bg-indigo-50 hover:bg-indigo-50 ': isActive,
                        }
                      )
                    }
                  >
                    <div className="flex items-center min-w-0 gap-x-4">
                      <img
                        className="h-8 w-8 flex-none rounded-full bg-gray-50"
                        src={getNameAndImage().image?.toString() || constants.ALT_AVATAR_URL}
                        alt="Avatar"
                      />
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          <span className="absolute inset-x-0 -top-px bottom-0" />
                          {getNameAndImage().name}
                        </p>
                        <p className="flex text-xs leading-4 text-gray-500">
                          {dayjs(conversation.updatedAt).format('MMMM DD, YYYY hh:mm A')}
                        </p>
                      </div>
                    </div>
                    <div className="flex shrink-0 items-center gap-x-4">
                      <ChevronRightIcon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </NavLink>
                </li>
              );
            })}
          </>
        )}
      </ul>
    </>
  );
};

export default RecipientsPanel;
