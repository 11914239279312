import PitchDeck from './PitchDeck';

export default function DealInformation() {
  return (
    <>
      <div className="space-y-9">
        <div>
          <div className="flex flex-col items-center mt-8 space-y-4">
            <div className="flex space-x-5">
              <div className="relative">
                <img
                  className="h-16 w-16 rounded-lg object-contain bg-white"
                  src="https://d25y2631pz6lli.cloudfront.net/OISTER+22-16.jpg"
                  alt="Oister Logo"
                />
                <span
                  className="absolute inset-0 rounded-lg shadow-inner"
                  aria-hidden="true"
                ></span>
              </div>
              <div className="relative">
                <img
                  className="h-16 w-16 rounded-lg object-contain bg-white"
                  src="https://d25y2631pz6lli.cloudfront.net/Tribe_Capital_logo.png"
                  alt="Tribe Capital Logo"
                />
                <span
                  className="absolute inset-0 rounded-lg shadow-inner"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
            <h1 className="text-3xl font-bold text-gray-900 text-center">
              Oister X Tribe Ace Fund I
            </h1>
          </div>
          <div className="h-8" />
        </div>
        <div className="mt-8">
          <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">PitchDeck</h2>
          <PitchDeck />
        </div>
        <div className="mt-8">
          <p className="mt-4 text-gray-700">
            Oister x Tribe Ace Fund I combines Oister’s relationships and insights with Tribe’s
            quantitative rigour to unlock high-quality investment opportunities at discounted
            valuations.
          </p>

          <p className="mt-4 text-gray-700">The fund has identified:</p>

          <ul className="mt-4 list-disc list-inside text-gray-700 space-y-2">
            <li>
              A select basket of 6-7 companies, with 4-5 likely to be included in the portfolio.
            </li>
            <li>Opportunities typically inaccessible in the non-institutional space.</li>
            <li>Companies poised for an IPO within the next 2-3 years.</li>
            <li>Secondaries at discounted valuations, providing a safety cushion for investors.</li>
            <li>
              Sectoral diversification to complement public markets, including Logistics-tech,
              E-commerce, B2B SaaS, and EV as a service.
            </li>
          </ul>
          <p className="mt-4 text-gray-700">
            The shortlist features companies such as Blackbuck (Logistics Tech), Shiprocket
            (E-commerce), OfBusiness (B2B managed marketplace), Zypp (Electric Vehicle), and
            Bluestone (Gems & Jewelry).
          </p>
        </div>

        <div className="pb-32 pt-8">
          <div className="space-y-4">
            <p className="text-xs font-light text-gray-500">
              Certain information contained herein has been obtained from third-party sources. While
              taken from sources believed to be reliable, AngelList India has not independently
              verified this information and makes no representations about its enduring accuracy.
              Under no circumstances should this be construed as an offer soliciting the purchase or
              sale of any security or interest in any pooled investment vehicle sponsored,
              discussed, or mentioned by AngelList India. Any offers to invest will be made
              separately and only through the confidential offering documents of the specific pooled
              investment vehicles—which should be read in their entirety—and only to those who,
              among other requirements, meet certain qualifications under Indian securities
              regulations. Such investors, defined as accredited investors, are generally deemed
              capable of evaluating the merits and risks of prospective investments and financial
              matters.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
