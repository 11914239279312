import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

const SYNDICATE_DEAL_INVITES_QUERY = gql(`
  query GetActiveDealInvitesFromSyndicate($cursor: ID, $filters: DashboardDealInvitesFilterType) {
    dashboard {
      id
      dealInvites(cursor: $cursor, filters: $filters) {
        nodes {
          id
          deal {
            id
            closingDate
            companyInformation {
              id
              company {
                id
                name
                image
              }
            }
          }
          status
          createdAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const DealInvitesOfSyndicate: FC<{ syndicateId: string }> = ({ syndicateId }) => {
  const navigate = useNavigate();
  const { loading, error, data, refetch, fetchMore } = useQuery(SYNDICATE_DEAL_INVITES_QUERY, {
    variables: {
      filters: {
        syndicateId,
      },
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const invites = data.dashboard.dealInvites.nodes;
  const pageInfo = data.dashboard.dealInvites.pageInfo;

  return (
    <DataTable
      data={invites}
      onClick={invite => navigate(`/invest/deal-invites/${invite.id}`)}
      emptyListTitle="No new deal invites"
      emptyListDescription="Please check back later for new deal invites."
      columns={[
        {
          label: 'Company',
          fieldName: 'deal.companyInformation.company',
          type: 'IMAGE_WITH_NAME',
        },
        {
          label: 'Status',
          fieldName: 'status',
          type: 'STATUS',
        },
        {
          label: 'Created At',
          fieldName: 'createdAt',
          type: 'DATETIME',
        },
      ]}
      hasNextPage={pageInfo.hasNextPage}
      onLoadMore={() =>
        fetchMore({
          variables: {
            cursor: pageInfo?.cursor,
          },
        })
      }
    />
  );
};

export default DealInvitesOfSyndicate;
