import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useState } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';
import LoadingIndicator from 'primitives/LoadingIndicator';

const GET_POST_QUERY = gql(`
  query GetPostForPublish($id: ID!) {
    post(id: $id) {
      id
      status
      deal {
        id
        companyInformation {
          company {
            name
          }
        }
      }
      targetClosingStatuses
    }
  }
`);

const SUBMIT_FOR_APPROVAL = gql(`
  mutation PublishPostFromDeal($id: ID!) {
    submitPostForApproval(id: $id) {
      id
      status
    }
  }
`);

const PublishPostDialogButton = ({ id }: { id: string }) => {
  const [open, setOpen] = useState(false);
  const { data, error, loading } = useQuery(GET_POST_QUERY, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const [publishPost, { loading: publishLoading, error: publishError }] =
    useMutation(SUBMIT_FOR_APPROVAL);

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} />;

  const post = data.post;

  function getTargetAudience() {
    if (!post.deal) {
      return 'all your syndicate Members';
    }

    if (!post.targetClosingStatuses || post.targetClosingStatuses.length === 0) {
      return 'all your syndicate Members';
    }

    return `investors of ${
      post.deal.companyInformation.company.name
    } whose investments are in ${post.targetClosingStatuses
      .map(status => status.toLowerCase())
      .join(', ')} status`;
  }

  return (
    <>
      <Button loading={publishLoading} onClick={() => setOpen(true)}>
        Submit for Approval
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Submit this post</DialogTitle>
          <DialogSubTitle>
            This action will send this post to {getTargetAudience()}, once approved by our admins.
          </DialogSubTitle>
          {publishError && <ErrorMessage error={publishError} />}
          <DialogActions className="sm:grid-cols-2">
            <Button variant="secondary" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => publishPost({ variables: { id } }).then(() => setOpen(false))}
            >
              Submit for Approval
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default PublishPostDialogButton;
