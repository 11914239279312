import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import { FormInput, FormPanel } from 'components/FormPanel';
import { TagField } from 'components/TagInput';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';
import Toast from 'primitives/Toast';
import useToast from 'primitives/Toast/useToast';

const INVITE_USERS_TO_SYNDICATE_MUTATION = gql(`
  mutation InviteUsersToSyndicate(
    $syndicateId: ID!
    $emailIds: [String!]
    $note: String
    $privateNote: String
  ) {
    inviteUsersToSyndicate(
      syndicateId: $syndicateId
      emailIds: $emailIds
      note: $note
      privateNote: $privateNote
    ) {
      id
      email
      status
      note
    }
  }
`);

const InviteUsersToSyndicateDialogButton: FC<{ syndicateId: string }> = ({ syndicateId }) => {
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState<Array<string>>([]);
  const { showToast, handleToastOpen, handleToastClose } = useToast();

  const [inviteUsersToSyndicate, { loading, error }] = useMutation(
    INVITE_USERS_TO_SYNDICATE_MUTATION,
    {
      refetchQueries: ['SyndicateInvites'],
    }
  );

  return (
    <>
      <Button onClick={() => setOpen(true)}>Invite To Syndicate</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Invite Investor To Syndicate</DialogTitle>
          <DialogSubTitle>They will receive an email invitation to accept.</DialogSubTitle>
          <TagField
            onTagsChange={tags => setTags(tags)}
            description="Enter emails of LPs you want to invite to this fund"
          />
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => setOpen(false)}
            onSubmit={data => {
              if (!tags.length) return;
              inviteUsersToSyndicate({
                variables: {
                  syndicateId,
                  emailIds: tags,
                  note: data.note,
                  privateNote: data.privateNote,
                },
              }).then(() => {
                setTags([]);
                handleToastOpen();
                setOpen(false);
              });
            }}
          >
            <FormInput
              fullWidth
              type="text"
              fieldName="note"
              label="Note"
              defaultValue=""
              helperText='Optional note to include in the invitation email. For example, "Hi, I would like to invite you to join my syndicate."'
            />
            <FormInput
              fullWidth
              type="text"
              fieldName="privateNote"
              label="Private Note"
              defaultValue=""
              helperText="Optional note to share between co-leads"
            />
          </FormPanel>
          <Toast
            title="Success"
            message={'Invites sent successfully'}
            show={showToast}
            handleClose={handleToastClose}
          />
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default InviteUsersToSyndicateDialogButton;
