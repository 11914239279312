import { AcceptanceStatusEnumType } from '__generated__/graphql';

export default function acceptanceStatusToReadable(acceptanceStatus: AcceptanceStatusEnumType) {
  switch (acceptanceStatus) {
    case AcceptanceStatusEnumType.ActivelyAccepting:
      return {
        label: 'Actively',
        color: 'green',
      };
    case AcceptanceStatusEnumType.SelectivelyAccepting:
      return {
        label: 'Selectively',
        color: 'gray',
      };
    case AcceptanceStatusEnumType.NotAvailable:
      return {
        label: '-',
        color: 'gray',
      };
    default:
      return {
        label: '-',
        color: 'gray',
      };
  }
}
