import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { getUser } from 'utils/auth';
import constants from 'utils/constants';

import Message from './Message';

const CONVERSATION_QUERY = gql(`
  query Conversation($id: ID!) {
    conversation(id: $id) {
      id
      users {
        id
        user {
          id
          name
          image {
            url
          }
        }
        isLead
      }
      syndicate {
        id
        name
        image
        syndicateLeads {
          user {
            id
          }
        }
      }
      messages {
        nodes {
          id
          message
          sender {
            id
            name
            image {
              url
            }
          }
          createdAt
        }
      }
    }
  }
`);

const ADD_MESSAGE_MUTATION = gql(`
  mutation SendMessage($conversationId: ID!, $message: String!) {
    addMessageToConversation(conversationId: $conversationId, message: $message) {
      id
      message
      sender {
        id
        name
      }
      createdAt
    }
  }
`);

const MessagingPanel: FC = () => {
  const { conversationId, syndicateId } = useParams<{
    conversationId: string;
    syndicateId?: string;
  }>() as { conversationId: string; syndicateId?: string };
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const currentUser = getUser();

  const { loading, error, data, refetch } = useQuery(CONVERSATION_QUERY, {
    variables: {
      id: conversationId,
      limit: 50,
    },
  });

  const [sendMessage, { loading: addMessageLoading, error: addMessageError }] = useMutation(
    ADD_MESSAGE_MUTATION,
    {
      update(cache, { data: mutationData }) {
        if (!mutationData) return;
        cache.modify({
          id: cache.identify({
            __typename: 'ConversationType',
            id: conversationId,
          }),
          fields: {
            messages(existingMessagesRef) {
              const newMessageNodeRef = cache.writeFragment({
                data: mutationData.addMessageToConversation,
                fragment: gql(`
                fragment NewMessage on MessageType {
                  id
                  message
                  sender {
                    id
                    name
                  }
                  createdAt
                }
              `),
              });
              const updatedMessagesRef = {
                nodes: [newMessageNodeRef, ...existingMessagesRef.nodes],
                pageInfo: {
                  ...existingMessagesRef.pageInfo,
                  cursor: mutationData.addMessageToConversation?.id,
                },
              };
              return updatedMessagesRef;
            },
          },
        });
      },
    }
  );

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [data]);

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const messages = data.conversation.messages.nodes;

    function getNameAndImage(conversation) {
      if (syndicateId && 'users' in conversation) {
        return {
          name: conversation.users
            .filter(user => user.isLead !== true)
            .map(user => user.user.name)
            .join(', '),
          image: conversation.users.filter(user => user.isLead !== true)[0]?.user.image?.url,
        };
      }

      if ('syndicate' in conversation && conversation.syndicate) {
        return {
          name: conversation.syndicate?.name,
          image: conversation.syndicate?.image,
        };
      }

      if ('users' in conversation) {
        return {
          name: `${conversation.users.map(user => user.user.name).join(', ')}`,
          image: conversation.users[0]?.user.image?.url,
        };
      }

      return {
        name: 'Missing Name',
        image: constants.ALT_AVATAR_URL,
      };
    }

    return (
      <div className="flex flex-col h-full border-l border-gray-100">
        <div className="bg-gray-50 flex items-center p-4">
          <img
            className="h-8 w-8 flex-none rounded-full bg-gray-50"
            src={getNameAndImage(data.conversation).image?.toString() || constants.ALT_AVATAR_URL}
            alt="Avatar"
          />
          <h1 className="pl-2 text-lg font-semibold">{getNameAndImage(data.conversation).name}</h1>
        </div>
        <div className="flex-1 flex flex-col-reverse overflow-y-auto p-4 gap-y-2">
          {/* Empty ref to help scroll to the bottom of the conversation; kept at the top 
          as the flex column is in reverse */}
          <div ref={bottomRef} />
          {messages.map(message => (
            <Message
              key={message.id}
              message={message.message}
              type={currentUser.id === message.sender.id ? 'SELF' : 'OTHER'}
              isLead={Boolean(
                data.conversation.syndicate?.syndicateLeads.some(
                  lead => lead.user.id === message.sender.id
                )
              )}
              sender={message.sender.name}
              createdAt={message.createdAt}
              image={message.sender.image?.url}
            />
          ))}
        </div>
        <div className="p-4 bg-gray-50 rounded-br-lg">
          <FormPanel
            loading={addMessageLoading}
            error={addMessageError}
            onSubmit={submitData => {
              sendMessage({
                variables: {
                  conversationId,
                  message: submitData.message,
                },
              }).then(() => {
                // Clear text area after sending a message
                submitData.message = '';
              });
            }}
            submitButtonLabel="Send Message"
          >
            <FormInput
              fieldName="message"
              type="textarea"
              placeholder="Type your message here"
              fullWidth
              defaultValue={''}
              label=""
            />
          </FormPanel>
        </div>
      </div>
    );
  };

  return renderContent();
};

export default MessagingPanel;
