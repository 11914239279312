import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import Statistics from 'components/Statistics';

import LoadingIndicator from 'primitives/LoadingIndicator';

const FUND_CLOSINGS_QUERY = gql(`
  query GetFundClosings($fundId: ID!) {
    fund(id: $fundId) {
      id
      statistics {
        totalRaisedAmount
        totalInvestedAmount
        balanceAmount
        numberOfClosings
      }
      fundClosings {
        nodes {
          id
          status
          createdAt
          wiredAt
          amount
          investmentEntity {
            name
          }
        }
      }
    }
  }
`);

const FundClosings: FC = () => {
  const { fundId } = useParams<{ fundId: string }>() as { fundId: string };

  const { loading, error, data, refetch } = useQuery(FUND_CLOSINGS_QUERY, {
    variables: {
      fundId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data || !data.fund) return <ErrorMessage error={error} refetch={refetch} />;

    const closings = data.fund.fundClosings.nodes;

    return (
      <>
        <Statistics
          statistics={[
            {
              label: 'Total Raised',
              value: data.fund.statistics.totalRaisedAmount,
              type: 'CURRENCY',
            },
            {
              label: 'Total Invested',
              value: data.fund.statistics.totalInvestedAmount,
              type: 'CURRENCY',
            },
            {
              label: 'Balance',
              value: data.fund.statistics.balanceAmount,
              type: 'CURRENCY',
            },
            {
              label: 'Number of LPs',
              value: data.fund.statistics.numberOfClosings,
              type: 'NUMBER',
            },
          ]}
        />
        <DataTable
          data={closings}
          columns={[
            {
              label: 'Name',
              fieldName: 'investmentEntity.name',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Invested On',
              fieldName: 'wiredAt',
              type: 'DATE',
            },
            {
              label: 'Amount',
              fieldName: 'amount',
              type: 'CURRENCY',
            },
          ]}
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Limited Partners"
        subTitle="View the investors of your fund here"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default FundClosings;
