import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import { TagField } from 'components/TagInput';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';
import Toast from 'primitives/Toast';
import useToast from 'primitives/Toast/useToast';

import CreateFundInviteLinkDialogButton from './CreateFundInviteLinkDialogButton';
import UpdateFundInviteLinkStatusDialogButton from './UpdateFundInviteLinkStatusDialogButton';

type FundInviteLink = {
  id: string;
  status: 'ACTIVE' | 'INACTIVE';
  minimumInvestmentAmount?: number;
  maximumInvestmentAmount?: number;
  note?: string;
  shortUrl: string;
  createdAt: string;
};

const FUND_INVITE_LINKS_QUERY = gql(`
  query GetFundInviteLinks($fundId: ID!) {
    fund(id: $fundId) {
      id
      inviteLinks {
        id
        status
        minimumInvestmentAmount
        maximumInvestmentAmount
        note
        shortUrl
        createdAt
      }
      invites {
        nodes {
          id
          status
          note
          emailStatus
          user {
            fullName
          }
          createdAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const INVITE_USERS_TO_FUND = gql(`
  mutation InviteUsersToFund($fundId: ID!, $emailIds: [String]) {
    inviteUsersToFund(fundId: $fundId, emailIds: $emailIds)
  }
`);

const FundNetwork: FC = () => {
  const { fundId } = useParams<{ fundId: string }>() as { fundId: string };
  const [inviteLinkOnDisplay, setInviteLinkDisplay] = useState<FundInviteLink | null>(null);
  const [tags, setTags] = useState<Array<string>>([]);

  const { loading, error, data, refetch } = useQuery(FUND_INVITE_LINKS_QUERY, {
    variables: {
      fundId,
    },
  });

  const [inviteUsersToFund] = useMutation(INVITE_USERS_TO_FUND);

  const { showToast, handleToastOpen, handleToastClose } = useToast();

  const handleSubmit = () => {
    if (tags.length > 0) {
      inviteUsersToFund({
        variables: {
          fundId,
          emailIds: tags,
        },
      });

      setTags([]);
      handleToastOpen();
    }
  };

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data || !data.fund) return <ErrorMessage error={error} refetch={refetch} />;

    const inviteLinks = data.fund.inviteLinks;
    const invites = data.fund.invites.nodes;

    return (
      <>
        {inviteLinkOnDisplay && (
          <UpdateFundInviteLinkStatusDialogButton
            id={inviteLinkOnDisplay.id}
            status={inviteLinkOnDisplay.status}
            onClose={() => setInviteLinkDisplay(null)}
          />
        )}

        <h2 className="text-lg font-bold">Invites</h2>
        <TagField
          onTagsChange={tags => setTags(tags)}
          description="Enter emails of LPs you want to invite to this fund"
        />
        <Button onClick={handleSubmit}>Invite to fund</Button>
        <DataTable
          data={invites}
          columns={[
            {
              label: 'Name',
              fieldName: 'user.fullName',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Note',
              fieldName: 'note',
            },
            {
              label: 'Email Status',
              fieldName: 'emailStatus',
            },
            {
              label: 'Created At',
              fieldName: 'createdAt',
              type: 'DATETIME',
            },
          ]}
        />

        <CreateFundInviteLinkDialogButton fundId={fundId} />

        <h3 className="text-lg font-bold">Invite Links</h3>
        <DataTable
          data={inviteLinks}
          onClick={inviteLink => setInviteLinkDisplay(inviteLink)}
          columns={[
            {
              label: 'Short URL',
              fieldName: 'shortUrl',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Minimum Investment Amount',
              fieldName: 'minimumInvestmentAmount',
              type: 'CURRENCY',
            },
            {
              label: 'Maximum Investment Amount',
              fieldName: 'maximumInvestmentAmount',
              type: 'CURRENCY',
            },
            {
              label: 'Note',
              fieldName: 'note',
            },
            {
              label: 'Created At',
              fieldName: 'createdAt',
              type: 'DATETIME',
            },
          ]}
        />

        <Toast
          title="Success"
          message={'Invites sent successfully'}
          show={showToast}
          handleClose={handleToastClose}
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Network"
        subTitle="View your fund invites and invite links here"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default FundNetwork;
