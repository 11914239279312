import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FundStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import ErrorMessage from 'components/ErrorMessage';
import Statistics from 'components/Statistics';

import LoadingIndicator from 'primitives/LoadingIndicator';

import constants from 'utils/constants';

import FundPortfolioChart from './FundPortfolioChart';
import UnderReviewBanner from './UnderReviewBanner';

const PORTFOLIO_QUERY = gql(`
  query FundPortfolio($id: ID!) {
    fund(id: $id) {
      id
      name
      image
      status
      statistics {
        totalUnrealizedValue
        totalInvestedAmount
        numberOfDeals
        multipleOnInvestment
      }
    }
  }
`);

function FundTitle({ name, image }: { name: string; image?: string | null }) {
  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-5 rounded-xl py-3 px-2 bg-gray-50">
      <div className="flex items-center space-x-3">
        <div className="flex-shrink-0">
          <div className="relative">
            <img className="h-10 w-10 rounded-xl" src={image ?? constants.ALT_AVATAR_URL} alt="" />
            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
          </div>
        </div>
        {/*
          Use vertical padding to simulate center alignment when both lines of text are one line,
          but preserve the same layout if the text wraps without making the image jump around.
        */}
        <div className="">
          <h2 className="text-2xl -mt-1 font-bold leading-7 text-gray-700 truncate tracking-tight">
            {name}
          </h2>
        </div>
      </div>
    </div>
  );
}

const FundPortfolio: FC = () => {
  const { fundId } = useParams<{ fundId: string }>() as { fundId: string };

  const { loading, error, data, refetch } = useQuery(PORTFOLIO_QUERY, {
    variables: {
      id: fundId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    if (data.fund.status === FundStatusEnumType.UnderReview)
      return (
        <>
          <FundTitle name={data.fund.name} image={data.fund.image} />
          <UnderReviewBanner />
        </>
      );

    return (
      <>
        <FundTitle name={data.fund.name} image={data.fund.image} />
        <Statistics
          statistics={[
            {
              label: 'Total Amount Invested',
              value: data.fund.statistics.totalInvestedAmount,
              type: 'CURRENCY',
            },
            {
              label: 'Total Unrealized Value',
              value: data.fund.statistics.totalUnrealizedValue,
              type: 'CURRENCY',
            },
            {
              label: 'Multiple on Investment',
              value: data.fund.statistics.multipleOnInvestment,
              type: 'MULTIPLE',
            },
            {
              label: 'Number of Holdings',
              value: data.fund.statistics.numberOfDeals,
              type: 'NUMBER',
            },
          ]}
        />
        <FundPortfolioChart fundId={fundId} />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Fund Portfolio"
        subTitle="View the performance of your fund"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default FundPortfolio;
