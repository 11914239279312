import { gql, useMutation, useQuery } from '@apollo/client';
import { OisterInviteStatusEnumType } from '__generated__/graphql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';
import Toast from 'primitives/Toast';
import useToast from 'primitives/Toast/useToast';

import DealInformation from './DealInformation';

const OISTER_INVITE_QUERY = gql(`
  query GetOisterInvite($userId: ID!) {
    oisterInvite(userId: $userId) {
      id
      status
    }
  }
`);

const UPDATE_OISTER_INVITE_MUTATION = gql(`
  mutation UpdateOisterInvite($userId: ID!, $status: OisterInviteStatusEnumType!) {
    updateOisterInvite(userId: $userId, status: $status) {
      id
      status
    }
  }
`);

const DealPage: FC = () => {
  const { userId } = useParams<{ userId: string }>() as { userId: string };

  const { loading, error, data, refetch } = useQuery(OISTER_INVITE_QUERY, {
    variables: {
      userId,
    },
    fetchPolicy: 'network-only',
  });

  const [updateOisterInvite, { loading: updateLoading }] = useMutation(
    UPDATE_OISTER_INVITE_MUTATION
  );
  const { showToast, handleToastOpen, handleToastClose } = useToast();
  const [responseSubmitted, setResponseSubmitted] = useState<boolean>(false);

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  // Check if the response is submitted or the current status is pending
  const shouldShowButtons =
    !responseSubmitted &&
    data.oisterInvite.status !== OisterInviteStatusEnumType.Interested &&
    data.oisterInvite.status !== OisterInviteStatusEnumType.Passed;

  return (
    <FocusLayout>
      <DealInformation />
      {shouldShowButtons && (
        <div className="fixed bottom-4 left-0 right-0">
          <div className="mx-auto max-w-3xl bg-white shadow-2xl p-4 grid grid-cols-6 gap-x-3 border-2 border-indigo-100 rounded-lg place-items-center">
            <Button
              disabled={data.oisterInvite.status === OisterInviteStatusEnumType.Interested}
              loading={updateLoading}
              className="col-span-3 w-full max-w-xs rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              onClick={() => {
                updateOisterInvite({
                  variables: {
                    userId,
                    status: OisterInviteStatusEnumType.Interested,
                  },
                }).then(() => {
                  setResponseSubmitted(true);
                  handleToastOpen();
                });
              }}
            >
              I’m Interested
            </Button>
            <Button
              disabled={data.oisterInvite.status === OisterInviteStatusEnumType.Passed}
              loading={updateLoading}
              className="col-span-3 w-full max-w-xs rounded-md border border-transparent bg-gray-500 px-4 py-3 text-base font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              onClick={() => {
                updateOisterInvite({
                  variables: {
                    userId,
                    status: OisterInviteStatusEnumType.Passed,
                  },
                }).then(() => {
                  setResponseSubmitted(true);
                  handleToastOpen();
                });
              }}
              variant="secondary"
            >
              I’m not Interested
            </Button>
          </div>
        </div>
      )}
      <Toast
        title="Success"
        message={'Submitted successfully'}
        show={showToast}
        handleClose={handleToastClose}
      />
    </FocusLayout>
  );
};

export default DealPage;
