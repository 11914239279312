import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import RecipientsPanel from 'components/Conversation/RecipientsPanel';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import MessageSyndicateDialog from './MessageSyndicateDialog';

const INVESTOR_CONVERSATIONS_QUERY = gql(`
  query InvestorConversationsQuery {
    dashboard {
      id
      conversations {
        nodes {
          id
          updatedAt
          syndicate {
            id
            image
            name
          }
          users {
            id
            user {
              id
              name
              image {
                url
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const InvestorConversations: FC = () => {
  const navigate = useNavigate();
  const [showCreateDialog, toggleCreateDialog] = useState(false);
  const { loading, error, data, refetch } = useQuery(INVESTOR_CONVERSATIONS_QUERY);
  const { conversationId } = useParams() as { conversationId: string };

  useEffect(() => {
    if (!conversationId && data?.dashboard.conversations.nodes.length) {
      const latestConversation = data.dashboard.conversations.nodes[0];
      navigate(`/invest/conversations/${latestConversation.id}`);
    }
  }, [conversationId, data, navigate]);

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const conversations = data.dashboard.conversations;

    return (
      <>
        {showCreateDialog && <MessageSyndicateDialog onClose={() => toggleCreateDialog(false)} />}
        <div className="grid grid-cols-6 border border-gray-100 rounded-lg">
          <div
            className="col-span-2 overflow-auto"
            style={{
              height: 'calc(100vh - 8rem)',
            }}
          >
            <RecipientsPanel
              conversations={conversations}
              onNewConversationClick={() => toggleCreateDialog(true)}
            />
          </div>
          <div
            className="col-span-4"
            style={{
              height: 'calc(100vh - 8rem)',
            }}
          >
            {conversations.nodes.length === 0 ? <div className="bg-gray-100 h-full" /> : <Outlet />}
          </div>
        </div>
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Your Conversations"
        subTitle="View conversations with syndicates here"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default InvestorConversations;
