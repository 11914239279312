import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FundClosingStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import debounce from 'utils/debounce';

const FUND_CLOSINGS_QUERY = gql(`
  query getCompletedFundCommitmentsOfUser(
    $cursor: ID
    $limit: Int
    $filters: DashboardFundClosingsFilterType
  ) {
    dashboard {
      id
      fundClosings(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          fund {
            id
            image
            name
            statistics {
              multipleOnInvestment
            }
          }
          investmentEntity {
            id
            name
          }
          amount
          status
          wiredAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const FundCommitmentsOfUser: FC = () => {
  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    FUND_CLOSINGS_QUERY,
    {
      variables: {
        filters: {
          statuses: [FundClosingStatusEnumType.Active],
        },
        limit: 20,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  let closings = data.dashboard.fundClosings.nodes;
  closings = closings.map(closing => ({
    ...closing,
    netValue: closing.fund.statistics.multipleOnInvestment
      ? closing.fund.statistics.multipleOnInvestment * closing.amount
      : '-',
  }));
  const pageInfo = data.dashboard.fundClosings.pageInfo;

  if (!closings.length) return null;

  return (
    <div>
      <p className="mt-4 text-md font-semibold">Fund Investments</p>
      <TextField
        placeholder="Search for a fund"
        onChange={debounce(e => {
          refetch({
            filters: {
              ...variables?.filters,
              text: e.target.value,
            },
          });
        }, 500)}
      />
      <DataTable
        data={closings}
        emptyListTitle="Coming soon…"
        emptyListDescription=" "
        columns={[
          {
            label: '',
            fieldName: 'fund.image',
            type: 'IMAGE',
          },
          {
            label: 'Fund',
            fieldName: 'fund.name',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Investment Entity',
            fieldName: 'investmentEntity.name',
          },
          {
            label: 'Invested',
            fieldName: 'amount',
            type: 'CURRENCY',
          },
          {
            label: 'Invest Date',
            fieldName: 'wiredAt',
            type: 'DATE',
          },
          {
            label: 'Net Value',
            fieldName: 'netValue',
            type: 'CURRENCY',
          },
          {
            label: 'Multiple',
            fieldName: 'fund.statistics.multipleOnInvestment',
            type: 'MULTIPLE',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        filterLoading={networkStatus === NetworkStatus.setVariables}
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </div>
  );
};

export default FundCommitmentsOfUser;
