import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormInput, FormPanel } from 'components/FormPanel';

import { Dialog, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import SearchSyndicateUsersForConversation from './SearchSyndicateUserForConversation';

type CreateConversationMutationResponse = {
  createConversation: {
    id: string;
  };
};

type CreateConversationMutationVariables = {
  syndicateId: string;
  message: string;
  receiverId?: string;
};

const CREATE_CONVERSATION_MUTATION = gql(`
  mutation CreateConversationFromSyndicateToUser($syndicateId: ID!, $message: String!, $receiverId: ID) {
    createConversation(syndicateId: $syndicateId, message: $message, receiverId: $receiverId) {
      id
    }
  }
`);

const MessageSyndicateUserDialog: FC<{
  syndicateId: string;
  onClose: () => void;
}> = ({ syndicateId, onClose }) => {
  const navigate = useNavigate();

  const [createConversation, { loading, error }] = useMutation<
    CreateConversationMutationResponse,
    CreateConversationMutationVariables
  >(CREATE_CONVERSATION_MUTATION, {
    refetchQueries: ['SyndicateConversations', 'Conversation'],
  });

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <DialogPanel>
          <DialogTitle>Message Investor</DialogTitle>
          <DialogSubTitle>
            This will create a private conversation between your syndicate and the investor.
          </DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onSubmit={data =>
              createConversation({
                variables: {
                  syndicateId: syndicateId,
                  receiverId: data.syndicateUser.user.id,
                  message: data.message,
                },
              }).then(response => {
                navigate(
                  `/syndicate-dashboard/${syndicateId}/conversations/${response.data?.createConversation.id}`
                );
                onClose();
              })
            }
            onCancel={onClose}
          >
            <FormInput
              type="custom"
              fieldName="syndicateUser"
              label="Name"
              defaultValue={''}
              customInput={SearchSyndicateUsersForConversation}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fullWidth
              type="textarea"
              label="Message"
              fieldName="message"
              defaultValue=""
            />
          </FormPanel>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default MessageSyndicateUserDialog;
