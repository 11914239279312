import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const APPLY_TO_SYNDICATE_MUTATION = gql(`
  mutation ApplyToSyndicate($syndicateId: ID!, $note: String, $discoveryMethod: SyndicateDiscoveryMethodEnumType!) {
    applyToSyndicate(syndicateId: $syndicateId, note: $note, discoveryMethod: $discoveryMethod) {
      id
      email
      status
      type
      syndicate {
        id
        permissions {
          canApplyToSyndicate
          canCancelSyndicateInvite
          canLeaveSyndicateAsUser
        }
      }
    }
  }
`);

const ApplyToSyndicateDialogButton: FC<{
  syndicateId: string;
  onClose: () => void;
}> = ({ syndicateId, onClose }) => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [applyToSyndicate, { loading, error }] = useMutation(APPLY_TO_SYNDICATE_MUTATION, {
    refetchQueries: ['SuggestedSyndicates', 'PendingSyndicates'],
  });

  return (
    <>
      <Button onClick={() => setOpen(true)}>Apply to Syndicate</Button>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
          setOpen(false);
        }}
      >
        <DialogPanel>
          <DialogTitle>Apply To Syndicate</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onSubmit={data =>
              applyToSyndicate({
                variables: {
                  syndicateId: syndicateId,
                  note: data.note,
                  discoveryMethod:
                    data.discoveryMethod === 'OTHER'
                      ? data.discoveryMethodOther
                      : data.discoveryMethod,
                },
              }).then(() => {
                navigate('/invest/syndicates/pending');
                onClose();
                setOpen(false);
              })
            }
            buttonRef={buttonRef}
          >
            <FormInput
              fullWidth
              type="text"
              fieldName="note"
              label="Note"
              defaultValue=""
              helperText='Optional note to include in the invitation email. For example, "Hi, I would like to invite you to join my syndicate."'
            />
            <FormInput
              fullWidth
              type="select"
              fieldName="discoveryMethod"
              label="How did you discover this syndicate?"
              defaultValue=""
              options={[
                {
                  label: 'I discovered it on the AngelList India platform',
                  value: 'THROUGH_AL_INDIA_PLATFORM',
                },
                {
                  label: 'I know or have communicated with the lead',
                  value: 'THROUGH_LEAD',
                },
                {
                  label: 'I heard about it through a friend or colleague',
                  value: 'THROUGH_FRIEND',
                },
                {
                  label: 'I heard about it via social media',
                  value: 'THROUGH_SOCIAL_MEDIA',
                },
                {
                  label: 'Other',
                  value: 'OTHER',
                },
              ]}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fullWidth
              type="text"
              fieldName="discoveryMethodOther"
              label="Other"
              defaultValue=""
              conditionsToShow={{
                matches: [
                  {
                    field: 'discoveryMethod',
                    condition: '===',
                    value: 'OTHER',
                  },
                ],
              }}
            />
          </FormPanel>
          <DialogActions>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Apply
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default ApplyToSyndicateDialogButton;
