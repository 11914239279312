import PDFViewer from 'components/PDFViewer';

const PitchDeck = () => {
  function renderContent() {
    return (
      <PDFViewer url={'https://d25y2631pz6lli.cloudfront.net/Oister%20x%20Tribe%20deck.pdf'} />
    );
  }

  return <div>{renderContent()}</div>;
};

export default PitchDeck;
