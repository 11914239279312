import { ChangeEvent, useEffect, useState } from 'react';

type TagProps = {
  onTagsChange: (tags: string[]) => void;
  passedTags?: string[];
  description?: string;
};

export const TagField = ({ onTagsChange, passedTags = [], description }: TagProps) => {
  const [userInput, setUserInput] = useState<string>('');
  const [tags, setTags] = useState<Array<string>>(passedTags);

  useEffect(() => {
    if (userInput.trim() === '') return;
    if (userInput && !tags.includes(userInput)) {
      onTagsChange([...tags, userInput]);
    }
    // eslint-disable-next-line
  }, [tags, userInput]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission or new line creation

      if (userInput.trim() !== '') {
        if (userInput && !tags.includes(userInput)) {
          setTags([...tags, userInput]);
        }
        setUserInput(''); // Clear the input after adding a tag
      }
    }
  };

  return (
    <div className="flex flex-col w-[300px] md:w-[400px]">
      {description ? <span className="text-sm text-gray-500 mb-2">{description}</span> : null}

      <input
        name="keyword_tags"
        type="text"
        placeholder="Click enter to add multiple emails"
        className="w-full border border-gray-300 rounded-md p-2 placeholder:text-sm placeholder:text-gray-400"
        onKeyDown={handleKeyPress}
        onChange={handleInputChange}
        value={userInput}
      />

      <div className="flex flex-row flex-wrap gap-1 mt-2 mb-3">
        {[...(userInput.trim() === '' ? [] : [userInput]), ...tags].map(
          (tag: string, index: number) => (
            <span
              key={`${index}-${tag}`}
              className="inline-flex items-start justify-start px-3 py-2 rounded-[32px] text-sm shadow-sm font-medium bg-blue-100 text-blue-800 "
            >
              {tag}
              <button
                className="ml-2 hover:text-blue-500"
                onClick={() => setTags(tags.filter(t => t !== tag))}
                title={`Remove ${tag}`}
              >
                &times;
              </button>
            </span>
          )
        )}
      </div>
    </div>
  );
};
