import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC } from 'react';

import Badge from 'primitives/Badge';

import constants from 'utils/constants';

const Message: FC<{
  message: string;
  type: 'SELF' | 'OTHER';
  isLead: boolean;
  sender: string;
  createdAt: string;
  image?: string;
}> = ({ message, type, sender, createdAt, isLead, image }) => {
  return (
    <>
      <div
        className={classNames('flex items-end', type === 'SELF' ? 'justify-end' : 'justify-start')}
      >
        {type === 'OTHER' && (
          <img
            className="w-8 h-8 rounded-full"
            src={image || constants.ALT_AVATAR_URL}
            alt="Avatar"
          />
        )}
        <div
          className={classNames(
            'flex flex-col w-full max-w-[320px] leading-1.5 py-2 px-4 border-gray-200 ',
            type === 'SELF' ? 'rounded-xl bg-indigo-100' : 'rounded-e-xl rounded-ss-xl bg-gray-50'
          )}
        >
          {type !== 'SELF' && (
            <div className="flex items-center justify-between">
              <span
                className={classNames(
                  'text-xs font-semibold',
                  isLead ? 'text-indigo-500' : 'text-amber-500'
                )}
              >
                {sender}
              </span>
              <span className="text-xs font-normal text-gray-500">
                {isLead && <Badge label="Lead" color="indigo" />}
              </span>
            </div>
          )}
          <p className="text-sm font-base text-gray-900">{message}</p>
          <p className="text-[0.6rem] font-normal text-gray-500 mt-1">
            {dayjs(createdAt).format('hh:mm A MMM DD, YYYY')}
          </p>
        </div>
      </div>
    </>
  );
};

export default Message;
