import { useLazyQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DashboardSyndicatesEnumType } from '__generated__/graphql';
import { FC, useState } from 'react';

import Autocomplete from 'primitives/Autocomplete';
import Button from 'primitives/Button';

import constants from 'utils/constants';
import debounce from 'utils/debounce';

type Syndicate = {
  id: string;
  name: string;
  image?: string | null;
  userConnections: {
    conversation: {
      id: string;
    };
  };
};

const SEARCH_SYNDICATES_FOR_CONVERSATION_QUERY = gql(`
  query SearchSyndicateForConversation($filters: DashboardSyndicatesFilterType, $cursor: ID, $limit: Int) {
    dashboard {
      id
      syndicates(filters: $filters, cursor: $cursor, limit: $limit) {
        nodes {
          id
          name
          image
          userConnections {
            conversation {
              id
            }
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

// const SearchSyndicateForConversation: FC<{
//   value?: Syndicate;
//   label?: string;
// }> = ({ value, label = '' }) => {
//   const [currentValue, setCurrentValue] = useState<Syndicate | null>(value ?? null);
//   const [showMessageSyndicateDialog, setShowMessageSyndicateDialog] = useState<boolean>(false);
//   const navigate = useNavigate();
//   const [getSearchResults, { loading, error, data, refetch, variables }] = useLazyQuery(
//     SEARCH_SYNDICATE_USERS_FOR_CONVERSATION_QUERY
//   );

//   const ref = useRef<HTMLDivElement | null>(null);

//   const performSearch = searchTerm =>
//     getSearchResults({
//       variables: {
//         limit: 10,
//         filters: {
//           text: searchTerm.trim(),
//           type: DashboardSyndicatesEnumType.Backed,
//         },
//       },
//     });

//   function results() {
//     if (loading) return <LoadingIndicator />;

//     if (error) return <ErrorMessage error={error} refetch={refetch} />;

//     if (!data || !data.dashboard || variables?.filters?.text === '') return null;

//     const searchResults = data.dashboard.syndicates.nodes;

//     if (searchResults.length === 0)
//       return (
//         <p className="bg-white p-4 text-sm cursor-pointer border select-none hover:bg-gray-50">
//           No result found.
//         </p>
//       );

//     return (
//       <>
//         {searchResults.map(syndicate => {
//           return !showMessageSyndicateDialog ? (
//             <div
//               key={syndicate.id}
//               className="flex flex-row justify-between align-middle bg-white p-4 cursor-pointer border border-b-0 hover:bg-gray-50"
//               onClick={() => {
//                 setCurrentValue(syndicate);
//                 if (syndicate.userConnections.conversation)
//                   navigate(`/invest/conversations/${syndicate.userConnections.conversation?.id}`);
//                 else setShowMessageSyndicateDialog(true);
//                 performSearch('');
//               }}
//             >
//               <div className="flex flex-row space-x-3 align-middle">
//                 <img
//                   className="rounded-full w-8 h-8 object-cover border self-center"
//                   src={syndicate.image ?? constants.ALT_AVATAR_URL}
//                   alt="Syndicate logo"
//                 />
//                 <div className="flex flex-col justify-evenly">
//                   <p className="text-sm select-none">{syndicate.name}</p>
//                   <p className="text-xs select-none">
//                     {syndicate.tagline.length > 30
//                       ? syndicate.tagline.substring(0, 28) + '...'
//                       : syndicate.tagline}
//                   </p>
//                 </div>
//               </div>
//               <PaperAirplaneIcon className="w-5 h-5 self-center" />
//             </div>
//           ) : (
//             <div key={syndicate.id} />
//           );
//         })}
//       </>
//     );
//   }

//   return (
//     <>
//       <ClickAwayListener
//         onClickAway={() => {
//           performSearch('');
//           setCurrentValue(null);
//           setShowMessageSyndicateDialog(false);
//         }}
//       >
//         <div>
//           <TextField
//             ref={ref}
//             fullWidth
//             size="small"
//             label={label}
//             onBlur={e => (e.target.value = '')}
//             onChange={debounce(e => performSearch(e.target.value), 500)}
//             placeholder="Search by name"
//           />
//           <p className="text-[0.7rem] mt-1 text-gray-400">
//             Search for syndicates you have joined to start or view your conversation.
//           </p>
//           <Popper
//             open={true}
//             style={{ width: ref.current?.offsetWidth, zIndex: 10000 }}
//             anchorEl={ref.current}
//             placement="bottom-start"
//             onClick={e => e.preventDefault()}
//           >
//             <div>{results()}</div>
//           </Popper>
//         </div>
//       </ClickAwayListener>
//       {showMessageSyndicateDialog && currentValue && (
//         <MessageSyndicateDialogButton
//           syndicateId={currentValue.id}
//           onClose={() => setCurrentValue(null)}
//           showButton={false}
//         />
//       )}
//     </>
//   );
// };

// export default SearchSyndicateForConversation;

// const SEARCH_SYNDICATE_USERS_FOR_CONVERSATION_QUERY = gql(`
//   query SearchSyndicateUsersForConversation($text: String!, $syndicateId: String!, $cursor: ID, $limit: Int) {
//     search(text: $text, cursor: $cursor, limit: $limit) {
//       syndicateUsers(syndicateId: $syndicateId) {
//         nodes {
//           id
//           user {
//             id
//             name
//             image{
//               url
//             }
//           }
//           conversations {
//             nodes {
//               id
//             }
//           }
//         }
//       }
//     }
//   }
// `);

// const SearchSyndicateUsersForConversation: FC<{
//   syndicateId: string;
//   value?: SyndicateUser;
//   label?: string;
// }> = ({ value, syndicateId, label = '' }) => {
//   const [currentValue, setCurrentValue] = useState<SyndicateUser | null>(value ?? null);
//   const [showMessageUserDialog, setShowMessageUserDialog] = useState<boolean>(false);
//   const navigate = useNavigate();
//   const [getSearchResults, { loading, error, data, refetch, variables }] = useLazyQuery(
//     SEARCH_SYNDICATE_USERS_FOR_CONVERSATION_QUERY
//   );

//   const ref = useRef<HTMLDivElement | null>(null);

//   const performSearch = searchTerm =>
//     getSearchResults({
//       variables: {
//         text: searchTerm.trim(),
//         limit: 10,
//         syndicateId,
//       },
//     });

//   function results() {
//     if (loading) return <LoadingIndicator />;

//     if (error) return <ErrorMessage error={error} refetch={refetch} />;

//     if (!data || !data.search || variables?.text === '') return null;

//     const searchResults = data.search.syndicateUsers.nodes;

//     if (searchResults.length === 0)
//       return (
//         <p className="bg-white p-4 text-sm cursor-pointer border select-none hover:bg-gray-50">
//           No result found.
//         </p>
//       );

//     return (
//       <>
//         {searchResults.map(syndicateUser => {
//           return !showMessageUserDialog ? (
//             <div
//               key={syndicateUser.id}
//               className="flex flex-row justify-between align-middle bg-white p-4 cursor-pointer border hover:bg-gray-50"
//               onClick={() => {
//                 setCurrentValue(syndicateUser);
//                 if (syndicateUser.conversations?.nodes.length)
//                   navigate(
//                     `/syndicate-dashboard/${syndicateId}/conversations/${syndicateUser.conversations?.nodes[0].id}`
//                   );
//                 else setShowMessageUserDialog(true);
//                 performSearch('');
//               }}
//             >
//               <PaperAirplaneIcon className="w-5 h-5 self-center" />
//             </div>
//           ) : (
//             <div key={syndicateUser.id} />
//           );
//         })}
//       </>
//     );
//   }

//   return (
//     <>
//       <ClickAwayListener
//         onClickAway={() => {
//           performSearch('');
//           setCurrentValue(null);
//           setShowMessageUserDialog(false);
//         }}
//       >
//         <div>
//           <TextField
//             ref={ref}
//             fullWidth
//             size="small"
//             label={label}
//             onBlur={e => (e.target.value = '')}
//             onChange={debounce(e => performSearch(e.target.value), 500)}
//             placeholder="Search by name"
//           />
//           <p className="text-[0.7rem] mt-1 text-gray-400">
//             Search for users in your network to start or view your conversation.
//           </p>
//           <Popper
//             open={true}
//             style={{ width: ref.current?.offsetWidth, zIndex: 10000 }}
//             anchorEl={ref.current}
//             placement="bottom-start"
//             onClick={e => e.preventDefault()}
//           >
//             <div>{results()}</div>
//           </Popper>
//         </div>
//       </ClickAwayListener>
//       {showMessageUserDialog && currentValue && (
//         <MessageUserDialogButton
//           userId={currentValue.user.id}
//           syndicateId={syndicateId}
//           onClose={() => setCurrentValue(null)}
//           showButton={false}
//         />
//       )}
//     </>
//   );
// };

// export default SearchSyndicateUsersForConversation;

const SearchSyndicatesForConversation: FC<{
  onChange: (syndicate: Syndicate) => void;
  value?: Syndicate;
  label?: string;
  editable?: boolean;
}> = ({ value, onChange, label = '', editable = true }) => {
  const [currentValue, setCurrentValue] = useState<Syndicate | null>(value || null);
  const [getSearchResults, { loading, error, data }] = useLazyQuery(
    SEARCH_SYNDICATES_FOR_CONVERSATION_QUERY
  );

  if (currentValue) {
    return (
      <>
        <h2 className="text-base font-semibold mb-1.5">{label}</h2>
        <div className="ring-1 ring-gray-300 rounded-lg p-2">
          <div className="flex gap-x-2 items-start">
            <img
              src={currentValue.image || constants.ALT_AVATAR_URL}
              alt={currentValue.name}
              className="h-11 w-11 object-contain rounded-lg bg-gray-50"
            />
            <div>
              <h3 className="text-md font-medium">{currentValue.name}</h3>
              {/* <p className="text-sm text-gray-500">{currentValue.legalName}</p> */}
            </div>
          </div>
          {editable && (
            <div className="mt-2 flex gap-x-2 border-t pt-1">
              <Button
                variant="text"
                className="!text-gray-700 hover:!text-gray-500"
                onClick={() => setCurrentValue(null)}
              >
                Edit
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div>
      <h2 className="text-sm font-medium mb-1.5">{label}</h2>
      <Autocomplete
        options={
          data?.dashboard.syndicates.nodes.map(syndicate => ({
            id: syndicate.id,
            name: syndicate.name,
            image: syndicate.image,
          })) || []
        }
        loading={loading}
        error={error}
        onChange={e => {
          if (e) {
            // @ts-ignore
            onChange(data?.dashboard.syndicates.nodes.find(syndicate => syndicate.id === e.id));
          }
          setCurrentValue(
            // @ts-ignore
            data?.dashboard.syndicates.nodes.find(syndicate => syndicate.id === e.id)
          );
        }}
        // @ts-ignore
        value={currentValue ? { id: currentValue.id, name: currentValue.name } : null}
        onSearch={debounce(
          e =>
            getSearchResults({
              variables: {
                filters: {
                  text: e,
                  type: DashboardSyndicatesEnumType.Backed,
                },
                limit: 10,
              },
            }),
          500
        )}
        placeholder={'Search by name'}
      />
    </div>
  );
};

export default SearchSyndicatesForConversation;
